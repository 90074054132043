/**
 * Generates fluid size styles based on this thing:
 * https://github.com/cmalven/sass-toolkit
 * Specifically this bit:
 * https://github.com/cmalven/sass-toolkit/blob/v2.4.7/_fluid-size.scss#L75
 * Porting the entirety of this functionality over to styled-components would be
 * silly when ultimately we just want to move away from this thing entirely, so
 * this ports over just the functionality bits we currently need for the moment.
 */

import { css } from 'styled-components';
import fluidSizes from '@/lib/fluidSizes';
import mq from './mq';

export default (size, attr = 'margin-top', args = {}) => {
  if (size === 'none') {
    return css`
      margin-top: 0;

      ${mq({ from: 320 })} {
        margin-top: 0;
      }

      ${mq({ from: 1600 })} {
        margin-top: 0;
      }
    `;
  }

  const sizes = fluidSizes[size];
  let { default: min, max, calcStatic, calcFluid } = sizes ?? {};
  const { scale, adjust } = args;

  if (scale) {
    min = min * scale;
    max = max * scale;
    calcStatic = calcStatic * scale;
    calcFluid = calcFluid * scale;
  }
  if (adjust) {
    min = min + adjust;
    max = max + adjust;
    calcStatic = calcStatic + adjust;
  }

  return css`
    ${attr}: ${min}px;

    ${mq({ from: 320 })} {
      ${attr}: calc(${calcStatic}px + ${calcFluid}vw);
    }

    ${mq({ from: 1600 })} {
      ${attr}: ${max}px;
    }
  `;
};
