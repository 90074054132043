import styled, { css } from 'styled-components';
import typeStyle from '@/styles/js/fonts/typeStyles';
import { commonSpacingContent } from '@/styles/js/utils/spacing';
import richTextStyles from '@/styles/js/utils/richTextstyles';

/**
 * Text size
 * @param {string} size - `xlarge | large | medium-large | medium | small | smallest`
 */
const getTextSize = ({ size }) => ({
  'xlarge': css`
    ${richTextStyles('body-xl', 2.2)}
  `,
  'large': css`
    ${richTextStyles('body-l', 1.8)}
  `,
  'medium-large': css`
    ${richTextStyles('body-ml')}
  `,
  'medium': css`
    ${richTextStyles('body-m')}
  `,
  'small': css`
    ${richTextStyles('body-s')}
  `,
  'smallest': css`
    > * {
      ${richTextStyles('body-s')}
    }
  `
}[size]);

/**
 * Text color 
 * @param {string} color - `warning | subtle | reverse`
 */
const getTextColor = ({ color, theme }) => ({
  'warning': css`${richTextStyles(false, false, theme.colors.red)}`,
  'subtle': css`${richTextStyles(false, false, theme.colors.textLight)}`,
  'reverse': css`${richTextStyles(false, false, theme.colors.white)}`,
}[color]);

/**
 * Text alignment 
 * @param {string} alignment - `align-center | align-left`
 */
const getTextAlignment = ({ alignment }) => ({
  'align-center': css`
    text-align: center;
  `,
  'align-left': css`
    text-align: left;
  `,
}[alignment]);

/**
 * Text padding 
 * @param {string} padding - `pad-vertical | pad-top | pad-bottom`
 */
const getTextPadding = ({ padding }) => ({
  'pad-vertical': css`
    padding: 1.5rem 0;
  `,
  'pad-top': css`
    padding-top: 1.5rem;
  `,
  'pad-bottom': css`
    padding-bottom: 1.5rem;
  `,
}[padding]);

/**
 * Text border 
 * @param {string} border - `bordered | rounded-border | bottom-border`
 */
const getTextBorder = ({ border, theme }) => ({
  'bordered': css`
    border: 1px solid ${theme.colors.rule};
    border-radius: 0.2rem;
    padding: 1.2rem;
  `,
  'rounded-border': css`
    border: 1px solid ${theme.colors.primary};
    border-radius: 0.4rem;
    padding: 0.6rem 1.2rem;
  `,
  'bottom-border': css`
    border-bottom: 1px solid ${theme.colors.rule};
    margin-top: 0;
  `,
}[border]);

/**
 * Text margin 
 * @param {string} margin - `no-top-margin | margin-vertical`
 */
const getTextMargin = ({ margin }) => ({
  'no-top-margin': css`
    margin-top: 0;
  `,
  'margin-vertical': css`
    margin: 4rem 0;
  `,
}[margin]);

/**
 * Text line break 
 * @param {string} lineBreak - `with-line-breaks | no-line-break`
 */
const getTextLineBreak = ({ lineBreak }) => ({
  'with-line-breaks': css`
    white-space: break-spaces;
  `,
  'no-line-break': css`
    white-space: nowrap;
  `,
}[lineBreak]);

/**
 * Text font style 
 * @param {string} fontStyle - `bold | marquee`
 */
const getTextFontStyle = ({ fontStyle }) => ({
  'bold': css` 
    &&& > * {
      font-weight: bold;
    }
  `,
  'marquee': css`
    ${typeStyle('micro')}

    p {
      font-weight: bold;
      font-size: 1.2rem;
    }
  `,
}[fontStyle]);

/**
 * Styled Text
 * ---
 * A block of content supporting a limited subset of HTML elements.
 * 
 * (Optional) props
 * - `size` 
 *    - `xlarge` - Extra larger than usual text
 *    - `large` - Larger than usual text
 *    - `medium-large` - Slightly larger at small viewport
 *    - `medium` - Slightly smaller size
 *    - `small` - Even smaller size
 *    - `smallest` Smallest size
 * - `color` 
 *    - `warning` - For important content
 *    - `subtle` - For less important content
 *    - `reverse` - For use on dark backgrounds
 * - `alignment` - `align-center | align-left`
 * - `padding` - `pad-vertical | pad-top | pad-bottom`
 * - `border` 
 *    - `bordered` - Display simple border around entire element
 *    - `rounded-border` - Display rounded border around entire element in
 *    - `bottom-border` - Display simple border on bottom of content
 * - `margin` - `no-top-margin | margin-vertical`
 * - `lineBreak` - `with-line-breaks | no-line-break`
 * - `fontStyle` 
 *    - `bold` - Makes font weight bold
 *    - `marquee` - Bold + uppercase style
 * - `blockQuote` - `boolean`
 * - `copyable` - `boolean`
 * - `selected` - `boolean`
 * - `underline` - `boolean`
 */
export const Text = styled.div.attrs({
  className: 'Text',
})`
  ${commonSpacingContent()}

  ${richTextStyles('body', false, ({ theme }) => theme.colors.textBody)}

  ${getTextSize}
  ${getTextColor}
  ${getTextAlignment}

  ${getTextPadding}
  ${getTextMargin}
  ${getTextBorder}

  ${getTextLineBreak}
  ${getTextFontStyle}

  ${({ blockQuote, theme }) => blockQuote && css`
    border-left: 0.3rem solid ${theme.colors.textDisabled};
    padding-left: 1.2rem;
  `}

  ${({ copyable, theme }) => copyable && css`
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    padding: 1.2rem;
    border: 1px solid ${theme.colors.ruleMedium};
    border-radius: 0.2rem;
    box-shadow: 0 0 0 0 rgb(${theme.colors.text}, 0);
    transition: {
      duration: 150ms;
      property: box-shadow;
    }

    > *:active {
      background-color: red;
    }
  `}

  ${({ selected, theme }) => selected && css`
    box-shadow: 0 0 0 5px rgb(${theme.colors.text}, 0.1);
  `}

  ${({ underline }) => underline && css`
    text-decoration: underline;
  `}
`;