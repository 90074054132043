import { css } from 'styled-components';
import typeStyle from '@/styles/js/fonts/typeStyles';

import { absolute } from '@/styles/js/utils/pseudo';
import { commonSpacingContent } from '@/styles/js/utils/spacing';
import { em } from '@/styles/js/utils/units';

/**
  Rich Text Styles

  Common styling for text HTML elements

  NOTE: Converted sass mixin 
*/
const richTextStyles = (_typeStyle = false, _lineHeight = false, _color = false) => css`
  ${commonSpacingContent()}

  p,
  span,
  ol,
  ul {
    ${_typeStyle && css `
      ${typeStyle(_typeStyle)}
    `}
    ${_lineHeight && css`
      line-height: ${_lineHeight};
    `}
    ${_color && css`
      color: ${_color};
    `}

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    a {
      text-decoration: underline;
      color: ${props => props.theme.colors.accent};
    }
  }

  ul {
    > li {
      position: relative;
      padding-left: 1.7rem;

      &::before {
        ${absolute}

        content: '•';
        left: 0;
      }
    }
  }
`;

/**
 * Reset Form Select
 * ---
 * Reset a `<select>` element's default styles.
 */
export const resetFormSelect = css`
  background: transparent;
  border: none;
  border-radius: 0;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
`;

/**
 * Placeholder Color
 * @param {String} color - color string or theme color
 */
export const placeholderColor = (color = '#000') => css`
  &::input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${color};
  }

  &:placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: ${color};
    opacity: 1;
  }

  &::placeholder {
    /* Mozilla Firefox 19+ */
    color: ${color};
    opacity: 1;
  }

  &:input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${color};
  }

  &::input-placeholder {
    /* Microsoft Edge */
    color: ${color};
  }
`;

/**
 * Form Select Styles
 * ---
 * Common shared styling for elements that should look like form selects.
 */
export const formSelectStyles = ({ theme }) => css`
  ${typeStyle('form-input')}
  ${resetFormSelect}

  border: 1px solid ${theme.colors.accentTransparentDark};
  border-radius: 0.4rem;
  line-height: 1.8rem;
  padding: ${em(11, 16)} ${em(14, 16)} ${em(13, 16)};
  max-width: 40rem;
  display: block;
`;

/**
 * Remove Default Text Input Appearance
 * ---
 * Removes the default appearance set by some browsers.
 */
export const removeDefaultTextInputAppearance = css`
  appearance: none;
  border-radius: 0;
`;

/**
 * Common styling for text inputs. 
 * ---
 * Consolidated to make it easy to apply consistent styles to a variety of components.
 */
export const formText = ({ theme }) => css`
  ${typeStyle('form-input')}
  ${placeholderColor(theme.colors.textLight)}
  ${removeDefaultTextInputAppearance}

  display: block;
  width: 100%;
  border: none;
  padding: ${em(13, 16)} 0 ${em(15, 16)};
  border-bottom: 1px solid ${theme.colors.rule};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-bottom-color: ${theme.colors.slate};
  }
`;

export default richTextStyles;