import styled, { css } from 'styled-components';

import { scaleWithVW } from '@/styles/js/utils/scaling';
import typeStyle from '@/styles/js/fonts/typeStyles';

const colorRule = css`
  ${({ theme }) => theme.colors.rule};
`;
const colorAccent = css`
  ${({ theme }) => theme.colors.accent};
`;
const colorText = css`
  ${({ theme }) => theme.colors.accent};
`;

export const NavRow = styled.div`
  position: relative;
  padding: 2.3rem ${scaleWithVW('2.5rem')};

  border-top: 1px solid ${colorRule};
  border-bottom: 1px solid ${colorRule};
  cursor: pointer;

  & + & {
    border-top: none;
  }

  /* TODO Replace with styled component target */
  > .Icon {
    color: ${colorAccent};

    position: absolute;
    top: 0;
    bottom: 0;
    right: ${scaleWithVW('2.5rem')};

    margin: auto 0;
    margin-right: -0.8rem;
  }
`;

export const Label = styled.span`
  ${typeStyle('body', 'medium')};

  line-height: 1;
  color: ${colorText};
  font-weight: bold;
`;

export const Text = styled.p`
  ${typeStyle('body', 'medium')};

  line-height: 1;
  color: ${colorAccent};
  margin-top: 1.2rem;
`;
