import styled, { css } from 'styled-components';

import mq from '@/styles/js/utils/mq';
import typeStyle from '@/styles/js/fonts/typeStyles';

/**
 * Styled Form Label
 * ---
 */
export const FormLabel = styled.label`
  ${({ isLarge }) => css`
    ${typeStyle('body', 'medium')}
    color: ${props => props.theme.text};
    font-weight: bold;

    > span {
      color: ${props => props.theme.red};
      margin-left: 0.2em;
    }

    ${isLarge && css`
      ${mq('medium')} {
        ${typeStyle('h2')}
      }
    `}
  `}
`;
